import React, { useState, useRef } from 'react'
import { useWindowSize, useMount } from 'react-use'
import { AgencyLogo, Button, Card, Flex, Input, ModalGeneric, Spinner, Text } from '../../../Components'
import { CommonColors, FC, showGrowl } from '../../../Services'
import { cap } from '../../../State'
import { getEnvDesign, verifyRequestCode } from '../functions'
import SignInformation from './SignInformation'
import { InputOtp } from 'primereact/inputotp'

const WelcomePage = ({ code, updatePage }) => {
  const [otpSent, setOtpSent] = useState(false)
  const [generalInfo, setGeneralInfo] = useState({})
  const [designPage, setDesignPage] = useState({})
  const [error, setError] = useState(false)
  const [skip, setSkip] = useState(false)
  const [inputOTP, setInputOTP] = useState('')
  const [spinnerOTP] = useState(false)
  const [isOTPWrong, setIsOTPWrong] = useState(false)
  const [isMultiSign, setIsMultiSign] = useState(false)
  const [newPhone, setNewPhone] = useState('')
  const [showWrongNumberModal, setShowWrongNumberModal] = useState(false)
  const [signExpired, setSignExpired] = useState(false)

  const modalRef = useRef(null)
  const { width: screenWidth } = useWindowSize()

  useMount(() => {
    verifyRequestCode(code)
      .then((res) => {
        if (!res) return showGrowl('error', 'Errore', 'Qualcosa è andato storto, riprova.')
        if (!res.ok) {
          res?.status === 'expired' && setSignExpired(true)
          return showGrowl('info', 'Attenzione', res.message || 'Codice non trovato, documento già firmato o cancellato.')
        }
        const { agency: { name, environment }, userEmail, userPhone, requestId, docType, forceOtp, isMultiSign } = res
        setIsMultiSign(isMultiSign)
        setGeneralInfo({ agencyName: name, userEmail, userPhone, requestId, environment, docType })
        setSkip((environment === 'blueteam' && docType === 'PRI') || forceOtp === 'noOtp')
      })
      .catch(() => showGrowl('error', 'Errore', 'Qualcosa è andato storto, riprova.'))

    getEnvDesign(code)
      .then((design) => {
        if (!design) {
          setError(true)
          return showGrowl('error', 'Errore', 'Qualcosa è andato storto, riprova.')
        }
        setDesignPage(design)
      })
      .catch((e) => {
        setError(true)
        showGrowl('error', 'Errore', 'Qualcosa è andato storto, riprova.')
      })
  })

  const verifyOtpRemote = async () => {
    try {
      const { status, data } = await FC.service('public').get('verifyOtpRemote', {
        query: { otp: inputOTP, code }
      })

      if (status !== 'OK') {
        setIsOTPWrong(true)
        return
      }

      updatePage(data, designPage)
    } catch (e) {
      setIsOTPWrong(true)
      setTimeout(() => {
        setInputOTP('')
        setIsOTPWrong(false)
      }, 2000)
    }
  }

  const requestOTP = async () => {
    modalRef?.current?.show?.()
    if (otpSent) return
    const response = await FC.service('public').get('getOtpRemote', { query: { code } })
    if (!response) return showGrowl('error', 'Errore', 'Errore nella richiesta OTP. Riprova.')
    setOtpSent(true)
  }

  const skipOTP = async () => {
    const res = await FC.service('public').get('getOtpRemote', { query: { code } })
    if (!res) return showGrowl('error', 'Errore', 'Qualcosa è andato storto, riprova.')
    await verifyOtpRemote()
  }

  const onClickAskOTP = async () => {
    if (skip) return skipOTP()
    return requestOTP()
  }

  const sendNewNumberToAgency = async () => {
    if (!newPhone) return showGrowl('error', 'Errore', 'Inserisci un numero di telefono valido.')
    const res = await FC.service('public').create({ action: 'sendNewNumberToAgency', signId: generalInfo?.requestId, newPhone })
    if (res) {
      showGrowl('success', 'Successo', 'Il numero è stato inviato con successo.')
      setNewPhone('')
      return true
    }
    return showGrowl('error', 'Errore', 'Qualcosa è andato storto, riprova.')
  }

  const contentModal = (
    <Flex>
      <InputOtp
        value={inputOTP || ''} length={5} onChange={(e) => setInputOTP(e.value)}
        invalid={isOTPWrong} style={{ marginBottom: '1em', position: 'relative' }}
      />
      {isOTPWrong && (<Text
        size={12}
        style={{ cursor: 'pointer', color: 'red', marginTop: '2px', position: 'absolute' }}
        value='Il codice inserito non è corretto. Riprova'
                      />
      )}

      {spinnerOTP
        ? <Spinner />
        : (
          <>
            {isMultiSign && (
              <Text
                style={{ marginTop: 10, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setShowWrongNumberModal(!showWrongNumberModal)}
                value="Non ricevi il codice di OTP? Premi qui per confermare il tuo cellulare e avvisare l'agenzia del problema"
              />)}
            {showWrongNumberModal && (
              <Flex style={{ margin: '10px 0px' }}>
                <Input id='newPhone' label='Cellulare:' onChange={({ newPhone: text }) => setNewPhone(text)} value={newPhone || ''} />
                <Text style={{ margin: '5px 0px' }} value="Cliccando Invia l'agenzia prenderà in carico la tua richiesta di ricevere l'OTP su numero differente" />
                <Button icon='send' label='Invia' onClick={sendNewNumberToAgency} />
              </Flex>
            )}
            <Button
              icon='send' label='Conferma OTP' inverted onClick={verifyOtpRemote}
              style={{ marginTop: 10, color: CommonColors.white, backgroundColor: designPage?.mainColor }}
            />
          </>)}
    </Flex>
  )

  if (error) return <Text value='Qualcosa è andato storto. Riprova o contatta la tua agenzia.' />
  return (
    <Flex fw fh style={{ margin: 10, padding: 10 }}>
      <ModalGeneric
        ref={modalRef}
        header='Inserisci codice OTP ottenuto via SMS'
        content={contentModal}
        style={screenWidth < cap.smallScreen.state ? { minWidth: screenWidth } : {}}
        styleText={{ textAlign: 'center' }}
        closable='yes'
      />
      <AgencyLogo logo={designPage.logo} />
      <Card style={{ padding: 50, marginTop: 100 }}>
        <SignInformation generalInfo={generalInfo} code={code} isExpired={signExpired} />
        <Flex>
          {generalInfo.agencyName && generalInfo.userEmail && generalInfo.userPhone && (
            <Button
              label={skip ? 'Procedi alla firma' : (designPage?.btnOtpText || 'Richiedi OTP')}
              icon={skip ? 'sign' : 'phone'}
              onClick={onClickAskOTP}
              style={{ backgroundColor: designPage?.mainColor, color: CommonColors.white, marginTop: 20 }}
              inverted
            />
          )}
        </Flex>
      </Card>
    </Flex>
  )
}

export default WelcomePage
