import { FC, invalidateQuery, showGrowl } from '../../../Services'
import { cap } from '../../../State'
import Papa from 'papaparse'

export const headerLabel = (text) => {
  switch (text) {
    case 'userName': return 'Nome'
    case 'userLastname': return 'Cognome'
    case 'userEmail': return 'Email'
    case 'userPhone': return 'Cellulare'
    case 'cap': return 'CAP'
    case 'cit': return 'Città'
    case 'prov': return 'Provincia'
    case 'piva': return 'P.Iva'
    case 'cfis': return 'Codice Fiscale'
    case 'descGr': return 'Descrizione Gruppo'
    case 'ind': return 'Indirizzo'
    case 'codUn': return 'Codice Univoco'
    case 'pec': return 'PEC'
    default: return text
  }
}

export const getLabel = (n) => {
  switch (n) {
    case 0: return 'userName'
    case 1: return 'userLastname'
    case 2: return 'userEmail'
    case 3: return 'userPhone'
    default: return ''
  }
}

export const uploadDocument = async (e) => {
  const readFile = (file) =>
    new Promise((resolve, reject) => {
      const reader = new window.FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  e.stopPropagation()
  e.preventDefault()
  e.persist()
  if (!e.target.files.length) return false
  const uri = await readFile(e.target.files[0])
  try {
    const { documentUrl, documentId } = await FC.service(`upload-${FC.uploadEnv}`).create({ uri })
    return { documentUrl, documentId }
  } catch (e) {
    console.log('ERROR => ', e)
  }
}

export const handleDeleteDoc = async (id) => {
  const [env] = await FC.service('environments').find({ query: { _id: cap.auth.state.environment } })
  if (!env) return showGrowl('error', 'Errore eliminazione documento', '')
  const newDocs = env?.documentsDefinition?.filter(({ multiSignId }) => multiSignId !== id)
  await FC.service('environments').patch(cap.auth.state.environment, { documentsDefinition: newDocs })
  invalidateQuery('multiSign')
  showGrowl('success', 'Cancellazione andata a buon fine.', '')
}

export const sendMultiSign = async (multiSignId, sendMode) => {
  window.growl.show({ severity: 'info', summary: 'Invio Firma Multipla in corso', detail: 'Inviando Firma Multipla...' })
  await FC.service('services').create({ action: 'sendMultiSign', multiSignId, sendMode })
  window.growl.show({ severity: 'success', summary: 'Firma Multipla Inviata', detail: 'Firma Multipla Inviata con successo!' })
  invalidateQuery(['multiSign', 'multiSignatures'])
}

export const uploadClients = async (e) => {
  const parseFile = (e) => {
    return new Promise(resolve => {
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          if (Object.keys(results.data[0]).length < 4) {
            console.log(Object.keys(results.data[0]).length)
            resolve([])
          }
          resolve(results.data.map(i => {
            const obj = {}
            Object.keys(i).forEach((key, ind) => {
              if (ind < 4) obj[getLabel(ind)] = i[key]
              else obj[key] = i[key]
            })
            return obj
          })?.filter(i =>
            i?.userPhone?.split('.')?.join('').match(/^[0-9]+$/) &&
               i?.userEmail?.includes('@') &&
                i?.userEmail?.length > 5 &&
                 (i?.userName || i?.userLastname)))
        }
      })
    })
  }
  const parsedData = await parseFile(e)
  return parsedData
}

export const importFromCRM = async () => {
  const [agency] = await FC.service('agencies').find({ query: { _id: cap.auth.state.agency } })
  const results = await FC.service('crm').get('clients', { query: { hasCel: true, codSoc: agency?.codSociety } })
  const crmUsers = results
    .filter(({ nome, cogn, mail, cel, codProfessione = '' }) => mail?.includes('@') && mail?.length > 5 && cel?.split('.')?.join('').match(/^[0-9]+$/) && (nome || cogn) && codProfessione !== 'FR1')
    .map(({ nome = '', cogn = '', mail = '', cel = '', ind = '', cap = '', cit = '', prov = '', piva = '', cfis = '', descGr = '', codUn = '', pec = '' }) => ({ userName: nome, userLastname: cogn, userEmail: mail, userPhone: cel, cap, cit, ind, prov, piva, cfis, descGr, codUn, pec }))
  return crmUsers
}

export const translateStatus = (status) => {
  switch (status) {
    case 'requested':
      return 'Inviata'
    case 'sending':
      return 'Inviando'
    case 'signed':
      return 'Firmata'
    case 'expired':
      return 'Scaduto'
    case 'cancelled':
      return 'Cancellata'
    default:
      return ''
  }
}

export const resendSignatures = async (multiSignId) => {
  window.growl.show({ severity: 'info', summary: 'Sollecito in corso', detail: 'Invio Sollecito in corso...' })
  const res = await FC.service('services').get('resendMultiSign', { query: { multiSignId } })
  res
    ? window.growl.show({ severity: 'success', summary: 'Sollecito Inviato', detail: 'Sollecito inviato con successo!' })
    : window.growl.show({ severity: 'error', summary: 'Errore invio sollecito', detail: 'Errore!' })
}

export const handleAction = (action) => {
  switch (action) {
    case 'edit':
      return {
        summary: 'Utente Modificato',
        detail: 'Utente Modificato con successo',
        severity: 'success'
      }
    case 'exclude':
      return {
        summary: 'Utente Escluso',
        detail: 'Utente Escluso dall\' invio',
        severity: 'error'
      }
    case 'include':
      return {
        summary: 'Utente Incluso',
        detail: 'Utente Incluso nell\' invio',
        severity: 'success'
      }
    default:
      return (`Unknown action: ${action}`)
  }
}

export const onEditClient = async (multisignId, { id: clientIndex, ...client }, action) => {
  const multiSign = await FC.service('multiSignatures').get(multisignId)
  const newClients = [...multiSign?.clients || []]
  newClients[clientIndex] = client
  const res = await FC.service('multiSignatures').patch(multisignId, { clients: newClients })
  invalidateQuery(['multiSign', 'multiSignatures'])
  const actionRes = handleAction(action)
  res && window.growl.show({ severity: actionRes.severity, summary: actionRes.summary, detail: actionRes.detail })
  return !!res
}

export const resendSingleSign = async (multiSignId, signId) => {
  const res = await FC.service('services').create({ action: 'resendSingleSingOfMultiSign', multiSignId, signId })
  if (res) {
    invalidateQuery(['multiSign', 'multiSignatures'])
    window.growl.show({ severity: 'success', summary: 'Firma Re-Inviata', detail: 'Firma reinviata con successo!' })
  }
}

export const addAndSendNewClients = async (multiSignId, clients) => {
  window.growl.show({ severity: 'info', summary: 'Aggiunta clienti', detail: 'Inviando Firma ai nuovi clienti...' })
  const res = await FC.service('services').create({ action: 'addClientsMultisign', multiSignId, clients })
  if (res) {
    window.growl.show({ severity: 'success', summary: 'Clienti aggiunti', detail: 'La richiesta è stata inviata ai nuovi clienti!' })
    return true
  }
  window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore aggiunta clienti' })
  invalidateQuery(['multiSign', 'multiSignatures'])
  return false
}

export const sendSignedDocs = async (multiSignId) => {
  const res = await FC.service('services').get('downloadMultiSignDocs', { query: { multiSignId } })
  window.growl.show({ severity: 'info', summary: 'Invio email in corso...', detail: "Riceverai l'email entro qualche minuto" })
  return !!res
}
