import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { headerLabel, onEditClient, resendSingleSign, translateStatus } from './Functions'
import { useParams } from 'react-router-dom'
import { useCQuery } from '../../../Services/QueryCache'
import { ModalEditClient } from './ModalEditClient'
import ModalContent from '../../Signatures/Components/ModalContent'
import { useEffect, useRef, useState } from 'react'
import { Button, Flex, ModalGeneric, Text } from '../../../Components'
import { MultiSignActions } from './Components'

export const MultiSignTable = ({ clients: [clients, setClients] }) => {
  const { multisignId } = useParams()
  const { data: multiSign } = useCQuery(['multiSign', multisignId])

  const [displayClients, setDisplayClients] = useState([])
  const [clientToEdit, setClientToEdit] = useState({})
  const [singleSignToResend, setSingleSignToResend] = useState('')
  const editRef = useRef()
  const resendSingleSignRef = useRef()
  const dtRef = useRef()

  const { status = '', signatures = [] } = multiSign || {}
  const showSignaturesStatus = status === 'requested' || status === 'sending' || status === 'signed'
  const isNew = multisignId === 'new'
  const showSortable = displayClients.length < 2000

  const editClient = (index) => {
    setClientToEdit({ id: index, ...clients[index] })
    editRef?.current?.show()
  }

  const toggleExclusion = ({ index, isClientExcluded, rowData }) => {
    const excludedClient = { id: index, ...clients[index], isClientExcluded: !clients[index]?.isClientExcluded }
    onEditClient(multisignId, excludedClient, isClientExcluded ? 'include' : 'exclude')
  }

  const renderBtns = ({ documentUrl, status, signatureId, index, isClientExcluded, mappedDocument }) => {
    return (
      <Flex style={{ width: 300 }} row>
      {multisignId !== 'new' && (
        <Button tooltip='Modifica Cliente' style={{ marginRight: 10 }} round icon='edit' onClick={() => editClient(index)} disabled={multiSign?.status === 'sending'} />
      )}
        {showSignaturesStatus && (
          <>
            <Button disabled={status !== 'signed'} tooltip='Apri documento firmato' round icon='download' onClick={() => window.open(documentUrl)} />
            {status !== 'excluded' &&
              <Button disabled={multiSign?.status === 'sending'} style={{ marginLeft: 10 }} tooltip='Re-Invia Firma' round icon='send' onClick={() => { setSingleSignToResend(signatureId); resendSingleSignRef?.current?.show() }} />}
          </>)}
        {(status === 'created' || isNew) &&
          <Button
            tooltip='Rimuovi cliente' round icon='bin' onClick={() => {
              const copy = [...clients]
              copy.splice(index, 1)
              setClients(copy)
            }}
          />}
        {!isNew && !showSignaturesStatus &&
          <Button
            tooltip={isClientExcluded ? 'Includi Cliente' : 'Escludi Cliente'}
            style={{ marginLeft: 10 }}
            round
            icon={isClientExcluded ? 'add' : 'close'}
            onClick={() => toggleExclusion({ index, isClientExcluded })}
          />}
      </Flex>
    )
  }

  const dtHeader = <MultiSignActions clients={clients} multisignId={multisignId} isNew={isNew} status={status} exportCSV={() => dtRef?.current?.exportCSV()} />

  const addStatus = (clients) => clients.map((item, i) => ({
    ...item,
    index: i,
    signatureId: item.isClientExcluded ? 'excluded' : signatures[i]?._id,
    status: item.isClientExcluded ? 'excluded' : signatures[i]?.status || 'sending',
    statusLabel: item.isClientExcluded ? 'Escluso' : translateStatus(signatures[i]?.status || 'sending'),
    documentUrl: signatures[i]?.documents?.[0]?.documentUrlSigned || ''
  }))

  useEffect(() => setDisplayClients(addStatus(clients)), [JSON.stringify(clients), JSON.stringify(signatures)])

  return (
    <>
      <DataTable
        style={{ width: '100%', boxShadow: '0 0 10px rgb(80, 80, 80)', borderRadius: 20, padding: 10 }} sortField='createdAt' sortOrder={-1} scrollHeight='90vh' ref={dtRef}
        value={displayClients} emptyMessage='Niente da visualizzare' responsiveLayout='scroll' paginator rows={10} className='TableSignatures' filterDisplay='row'
      >
        {Object.keys(clients[0]).map((key, ind) => (
          key !== 'isClientExcluded'
            ? (
              <Column
                key={ind} header={headerLabel(key)} alignHeader='center' showClearButton={false} filter
                filterMatchMode='contains' style={{ textAlign: 'center' }} sortable={!!showSortable}
                filterPlaceholder={'🔍   ' + headerLabel(key)} showFilterMenu={false} field={key}
                body={({ [key]: value }) => <Text center value={value} style={{ width: 200 }} className='TableSignatures' />}
              />
              )
            : null

        ))}
        {showSignaturesStatus && <Column header='Stato firma' filter filterPlaceholder={'🔍   ' + 'Stato Firma'} showClearButton={false} filterMatchMode='contains' showFilterMenu={false} alignHeader='center' style={{ textAlign: 'center', minWidth: '200px' }} sortable={!!showSortable} field='statusLabel' body={({ statusLabel }) => <Text center value={statusLabel} />} />}
        <Column header={dtHeader} style={{ textAlign: 'center' }} body={renderBtns} />
      </DataTable>
      <ModalGeneric ref={resendSingleSignRef} header='Re-Invio firma' content={<ModalContent onHide={() => resendSingleSignRef.current.hide()} onConfirm={() => { resendSingleSign(multisignId, singleSignToResend); resendSingleSignRef.current.hide() }} contentText='Sei sicuro di volere reinviare la richiesta di firma il documento? La richiesta di firma attuale verrà cancellata' />} />
      <ModalEditClient editRef={editRef} header='Modifica cliente' clientToEdit={clientToEdit} saveClient={(client) => onEditClient(multisignId, client, 'edit')} />
    </>
  )
}
