import React, { useState, useRef, useEffect } from 'react'
import { Flex, Button, Text, ModalGeneric, Spinner, Card } from '../../../../../Components'
import { useMount } from 'react-use'
import { generateCustomQuestion, generateCustomRadioQuestion, mountFn, saveAll } from './Functions'
import DraggableSignDate from '../DraggableSignDate'
import { CustomQuestion } from './CustomQuestion'
import { DateSign } from './DateSign'
import { CorrectButton } from './CorrectButton'
import DraggableCustomQuestion from '../DraggableCustomQuestion'
import DraggableCustomRadioQuestion from '../DraggableCustomRadioQuestion'
import { ModalSendRequest } from './ModalSendRequest'
import { useLocation, useNavigate } from 'react-router-dom'
import ModalContent from '../../../../Signatures/Components/ModalContent'
import { headerLabel } from '../../../../MultiSign/New/Functions'
import { CustomRadio } from './CustomRadio'

const OPERATION_LIST = [
  { label: 'Dati cliente', type: 'special', onlyMultiSign: true },
  { label: 'Firme', type: 'sign' },
  { label: 'Date', type: 'date' },
  { label: 'Testi fissi', type: 'fixedLabel' },
  { label: 'Crocette personalizzate', type: 'customCheck' },
  { label: 'Domanda a scelta multipla', type: 'RadioType' }
]

const OperationFixed = ({
  documentId: [documentId, setDocumentId], documentName: [documentName, setDocumentName],
  pageNumber, refContainer, documentLink, onMouseMove, awsLink
}) => {
  const { pathname, state } = useLocation()
  const isInstant = pathname.includes('/instantsign')
  const isMultiSign = pathname.includes('/multisign')
  const [elementAddedSignDate, setElementAddedSignDate] = useState([])
  const [listCustomQuestions, setListCustomQuestions] = useState([])
  const [listCustomRadioQuestions, setListCustomRadioQuestions] = useState([])
  const [infoText, setInfoText] = useState('')
  const refModalSign = useRef(null)
  const [isReady, setIsReady] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const navigate = useNavigate()
  const modalBack = useRef(null)

  useEffect(() => {
    setIsSaved(false)
  }, [elementAddedSignDate])
  

  useMount(async () => {
    const { documentId, documentName, elementAddedSignDate, listCustomQuestions, listCustomRadioQuestions } = await mountFn(documentLink, awsLink, state?.multiSignData?.multisignId)
    setIsReady(true)
    documentId && setDocumentId(documentId)
    documentName && setDocumentName(documentName)
    elementAddedSignDate && setElementAddedSignDate(elementAddedSignDate)
    elementAddedSignDate && setIsSaved(!!elementAddedSignDate.length)
    listCustomQuestions && setListCustomQuestions(listCustomQuestions)
    listCustomRadioQuestions && setListCustomRadioQuestions(listCustomRadioQuestions)
  })

  const handleSaveAll = async () => {
    await saveAll({ documentId, documentName, documentLink }, elementAddedSignDate, listCustomQuestions, listCustomRadioQuestions, true)
    navigate('/newrequest', { state: { docInfo: { documentId, documentName } } })
  }

  const handlerPositionClicked = ({ fixedLabel, type }) =>
    setElementAddedSignDate([
      ...elementAddedSignDate,
      {
        ...(fixedLabel ? { title: fixedLabel } : {}),
        coordinates: { x: 250, y: 400 },
        page: pageNumber,
        type,
        id: type + (Date.now()),
        progressiveNumber: elementAddedSignDate.length + 1,
        ...(type === 'sign' ? { width: 200, height: 45 } : {})
      }])

  const specialClick = (clientField) =>
    setElementAddedSignDate([
      ...elementAddedSignDate,
      {
        title: headerLabel(clientField),
        clientField,
        coordinates: { x: 250, y: 400 },
        page: pageNumber,
        type: 'special',
        id: 'special' + (Date.now()),
        progressiveNumber: elementAddedSignDate.length + 1
      }])

  const handleNewCustomCheck = (questionText) => setListCustomQuestions(generateCustomQuestion(listCustomQuestions, questionText))
  const handleNewCustomRadio = (questionText) => setListCustomRadioQuestions(generateCustomRadioQuestion(listCustomRadioQuestions, questionText, pageNumber))
  const renderSelectedItem = (type) => type === 'customCheck'
    ? (
      <Flex row fw wrap>
        {listCustomQuestions.map((item, index) =>
          <CustomQuestion
            key={index} item={item} page={pageNumber} id={index}
            questionsState={[listCustomQuestions, setListCustomQuestions]}
          />
        )}
      </Flex>)
    : type === 'RadioType'
      ? (
        <Flex fw wrap>
          {listCustomRadioQuestions.map((item, index) =>
            <CustomRadio
              key={index} item={item} page={pageNumber} id={index}
              radiosState={[listCustomRadioQuestions, setListCustomRadioQuestions]}
            />
          )}
        </Flex>
        )
      : (
        <Flex row fw wrap>
          {elementAddedSignDate.filter(el => el.type === type).map((item, index) => <DateSign id={index} signDate={[elementAddedSignDate, setElementAddedSignDate]} item={item} key={index} />)}
        </Flex>
        )

        const renderCardContent = (type) => 
          type === 'sign' ? (
            <Flex as="js" style={{ padding: '10px 0px', position: 'relative' }}>
              <Text 
                value="( Campo Obbligatorio )"
                italic
                size={12}
                style={{
                  color: !hasSignType(elementAddedSignDate) ? 'red' : '',
                  visibility: !hasSignType(elementAddedSignDate) ? 'visible' : 'hidden',
                  position: 'absolute',
                  top: -20,
                  left: 65,
                }}
              />
              <CorrectButton 
                specialClick={specialClick}
                type={type}
                signDateClick={handlerPositionClicked}
                customCheckClick={handleNewCustomCheck}
                customRadioCheckClick={handleNewCustomRadio}
                fixedLabelClick={(fixedLabel) =>
                  fixedLabel && handlerPositionClicked({ fixedLabel, type })
                }
                clientFields={state?.multiSignData?.clientField}
              />
              {renderSelectedItem(type)}
            </Flex>
          ) : (
            <Flex
              row={type !== 'customCheck' && type !== 'RadioType'}
              as="js"
              style={{ padding: '10px 0px' }}
            >
              <CorrectButton 
                specialClick={specialClick}
                type={type}
                signDateClick={handlerPositionClicked}
                customCheckClick={handleNewCustomCheck}
                customRadioCheckClick={handleNewCustomRadio}
                fixedLabelClick={(fixedLabel) =>
                  fixedLabel && handlerPositionClicked({ fixedLabel, type })
                }
                clientFields={state?.multiSignData?.clientField}
              />
              {renderSelectedItem(type)}
            </Flex>
          );
        
        const hasSignType = (array) => 
          Array.isArray(array) && array.some(item => item.type === 'sign');
        

  const marginTop = document.getElementsByClassName('marginDocumentPDF')[0]?.offsetTop

  if (!isReady) return <Spinner />
  return (
    <>
      <div onMouseMove={onMouseMove} ref={refContainer} style={{ width: 565, height: 799, position: 'absolute', zIndex: 20, border: '1px solid black', marginTop: marginTop }}>
        <DraggableSignDate pageNumber={pageNumber} listState={[elementAddedSignDate, setElementAddedSignDate]} parentRef={refContainer} />
        <DraggableCustomQuestion pageNumber={pageNumber} listState={[listCustomQuestions, setListCustomQuestions]} parentRef={refContainer} />
        <DraggableCustomRadioQuestion pageNumber={pageNumber} listState={[listCustomRadioQuestions, setListCustomRadioQuestions]} parentRef={refContainer} />
      </div>
      <Flex fw style={{ paddingTop: 20 }}>
        <Flex fw as style={{ marginBottom: 10, paddingLeft: 20 }}>
          <Card style={{ padding: 10, width: 565, height: 65, borderRadius: 30 }}>
            <Flex row jb style={{ height: 45 }}>
              {state?.hasFtoSign &&
                <Button
                  icon='send' label='Conferma' onClick={handleSaveAll}
                  disabled={!elementAddedSignDate.filter(({ type }) => type === 'sign').length}
                />}
              {!isMultiSign && !state?.hasFtoSign &&
                <Button
                  icon='send' label='Invia Richiesta di firma estemporanea' onClick={() => refModalSign.current.show()}
                  disabled={!elementAddedSignDate.filter(({ type }) => type === 'sign').length}
                />}
              <Button icon='back' label={isMultiSign ? 'Indietro' : 'Annulla'} onClick={() => modalBack.current.show()} style={{ marginLeft: 30 }} />
              <ModalGeneric
                ref={modalBack}
                header='Annulla richiesta di firma'
                content={
                  <ModalContent
                    onHide={() => modalBack.current.hide()}
                    onConfirm={() => navigate(isMultiSign ? -1 : '/')}
                    contentText={isMultiSign ? 'Vuoi tornare indietro?' : 'Vuoi annullare la richiesta di firma?'}
                  />
                }
              />
            </Flex>
          </Card>
        </Flex>
        <Flex style={{ marginLeft: 20, width: 'calc(100% - 20px)' }}>
          {OPERATION_LIST.filter(i => !i.onlyMultiSign || isMultiSign).map(({ label, type }, index) => (
            <Card key={index} style={{ width: '100%', margin: '10px 0px' }} title={label} collapsable={type !== 'sign' && type !== 'date'} collapsed>
              {renderCardContent(type)}
            </Card>))}
        </Flex>
        <Flex row>
          {!isInstant && !state?.hasFtoSign && (
            <Button
              style={{ marginTop: 10, marginRight: 10 }} label='Salva' icon='check' disabled={!hasSignType(elementAddedSignDate)}
              onClick={() => {
                saveAll({ documentId, documentName, documentLink }, elementAddedSignDate, listCustomQuestions, listCustomRadioQuestions, false, state?.multiSignData?.multisignId)
                setInfoText('Salvataggio avvenuto con successo!')
                setTimeout(() => setInfoText(''), 3000)
                setIsSaved(true)
              }}
            />)}
          {isMultiSign && isSaved &&
            <Button style={{ marginTop: 10 }} label='Torna indietro' icon='back' onClick={() => navigate(-1)} />}
          <ModalGeneric
            ref={refModalSign} style={{ width: '80%', height: '90%', maxWidth: 600, maxHeight: 700 }} noDismissable
            content={(
              <ModalSendRequest
                document={{ documentId, documentName, documentLink }} refModal={refModalSign}
                elementAddedSignDate={elementAddedSignDate} listCustomQuestions={listCustomQuestions} listCustomRadioQuestions={listCustomRadioQuestions}
              />)}
          />
        </Flex>
        <Text value={infoText} size={16} bold style={{ marginBottom: 10, color: 'white' }} />
      </Flex>
    </>
  )
}

export default OperationFixed
